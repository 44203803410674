export default {
  primary: {
    backgroundColor: 'secondary',
    color: 'light',
    border: 'none',
    border: 'solid 1px',
    transition: 'all ease-in-out 0.7s',
    backgroundSize: '100% 204%',
    backgroundImage: 'linear-gradient(to bottom,#ffc438 50%, black 50%)',
    borderColor: 'primary',
    padding: '1rem 2rem',
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: '1rem',
    textTransform: 'capitalize',
    borderRadius: '5px',
    ':hover': {
      backgroundColor: 'transparent',
      backgroundPosition: '0% -98%',
      color: 'light',
      borderColor: 'secondary'
    }
  },
  secondary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'none',
    border: 'solid 1px',
    transition: 'all ease-in-out 0.7s',
    backgroundSize: '100% 204%',
    backgroundImage: 'linear-gradient(to bottom,#00789d 50%, #ffc438 50%)',
    borderColor: 'primary',
    padding: '1rem 2rem',
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: '1rem',
    textTransform: 'capitalize',
    borderRadius: '5px',
    ':hover': {
      backgroundColor: 'transparent',
      backgroundPosition: '0% -98%',
      color: 'light',
      borderColor: 'secondary'
    }
  }
}
