export default {
  text: '#202020',
  // primary: '#059ff7',
  // secondary: '#f0d512',
  primary: '#00789d',
  secondary: '#ffc438',
  background: 'white',
  backgroundSecondary: 'lightgrey',
  light: '#FFF',
  dark: '#202020'
}
