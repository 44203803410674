import buttons from './buttons'

export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // ? ======= Nav Container Scrolled =======
    '.container': {
      padding: ['0.5rem', '0.5rem', '0.5rem', '1rem'],

      zIndex: '2000'
      // '.smallNavMenu > div': {
      //   backgroundColor: 'secondary',
      //   a: {
      //     color: 'white',
      //   },
      // },
    },

    // ? ======= Nav Container Scrolled =======

    '.containerScrolled': {
      backgroundColor: 'primary',
      zIndex: '2000',
      '.smallNavMenu > div': {
        a: {
          color: 'white'
        }
      }
    },

    // ? =======  Logo  =======

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },

    '.logo': {
      // display: 'none',
      img: {
        filter: 'unset',
        maxWidth: '250px'
      }
    },

    '.logoScrolled': {
      // display: 'none',
      img: {
        filter: 'unset',
        maxWidth: '100px',
        height: 'auto'
      }
    },

    // ? =======  Hamburger  =======

    '.hamburger': {
      backgroundColor: 'rgb(255 255 255 / 42%)'
    },

    '.hamburgerOpen': {
      borderColor: 'secondary',
      '> div': {
        backgroundColor: 'secondary'
      }
    },

    // ? ======= Desktop Small Nav Menu =======

    '.smallNavMenu': {
      '.navItem': {
        color: 'black',
        margin: ['', '', '', '0.75rem', '1rem'],

        a: {
          fontSize: '1rem',
          color: 'light',
          fontFamily: 'heading',
          textTransform: 'capitalize',
          ':hover': {
            color: 'secondary',
            textDecoration: 'underline'
          }
        }
      },
      '.navItemDropdownMenu': {
        width: 'auto'
      },
      '.react-reveal': {
        display: 'flex'
      }
    },

    // ? =======  Nav Menu  =======

    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['1rem', '2rem', '3rem', '3rem'],
      backgroundColor: 'primary',
      width: ['90%', '', '40%', '30%'],
      '.navMenuLogo': {
        alignSelf: 'flex-start',
        img: {
          // filter: 'brightness(0) invert(1)',
          maxWidth: '250px',
          height: 'auto',
          marginBottom: '2rem'
        }
        // display: 'none',
      },
      '.navItemDropdown': {
        width: '100%',
        margin: '0rem 1rem'
      },
      '.navItemDropdownMenu': {
        position: 'static',
        '.navItem': {
          paddingLeft: '1.5rem',
          a: {
            fontSize: '0.9rem'
          }
        }
      },

      '.navItem': {
        width: '100%',
        textAlign: 'left',
        borderBottom: '2px dotted white',
        paddingBottom: '0.5rem',
        transition: 'all ease-in-out 0.5s',
        paddingLeft: '1rem',
        a: {
          fontFamily: 'heading',
          textTransform: 'capitalize',
          color: 'white',
          fontSize: ['1.1rem', '1.25rem', '1.25rem', '1.25rem']
        },
        ':hover': {
          paddingLeft: '2rem',
          borderColor: 'secondary',
          a: {
            color: 'secondary'
          }
        }
      },

      // ? =======  Nav Item Dropdown  =======

      // ? =======  Widgets  =======

      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto'],
        display: 'none'
      },
      '.phoneSocialContainer': {
        marginTop: '2rem',
        a: {
          textAlign: ['left'],
          padding: '0rem',
          color: 'white',
          justifyContent: 'flex-start'
        },
        svg: {
          path: {
            fill: 'white'
          }
        },
        '.smallNavHeading ': {
          textAlign: ['left'],
          fontSize: '1rem',
          color: 'secondary'
        },

        '.socialIconsContainer': {
          alignItems: 'flex-start',
          a: {
            padding: '0rem 0.5rem 0rem 0rem'
          }
        }
      }
    },

    // ? =======  Nav Menu opaque background  =======

    '.navBlockOpen': {
      backgroundColor: 'rgb(18 66 55 / 77%)'
    }
  },

  footer: {
    padding: ['', '', '', '5.5rem 4rem'],
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/w_1350,c_fit,fl_lossy,f_auto,q_auto/gonation.data.prod/fvxmwhc1smgezmw9p4tl)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '.socialContainer': {
      'svg path': {
        fill: 'black'
      }
    },
    '.gonationLogo': {
      filter: 'brightness(0)'
    }
  },
  ctaWidget: {},

  pageHero: {
    height: '60vh',
    h1: {
      letterSpacing: '3px',
      textTransform: 'capitalize',
      color: 'secondary',
      borderTop: '3px dotted',
      borderBottom: '3px dotted',
      borderColor: 'secondary',
      paddingBottom: '0.5rem',
      background: '#00789d7a',
      padding: '2rem'
    }
  },

  // ? ======================
  // ? ====  Reuseables  ====
  // ? ======================

  title: {
    order: '1',
    color: 'primary',
    fontFamily: 'heading',
    fontSize: ['2rem', '', '2.5rem', '2.75rem'],
    margin: '0rem 0rem 2rem',
    textTransform: 'capitalize',
    order: '1'
  },
  subtitle: {
    fontFamily: 'display',
    fontSize: ['1.25rem', '', '1.5rem', '2rem'],
    order: '2',
    borderBottom: '1px solid',
    width: 'fit-content',
    margin: '0rem 0rem 1.5rem',
    color: 'secondary',
    order: '2'
  },

  text: {
    order: '3',
    opacity: '0.7'
    // color: '',
  },

  sideBySide1: {
    padding: ['2rem 0rem', '', '0rem'],
    '.content': {
      padding: ['1rem', '', '1rem 4rem'],
      position: 'relative'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: { order: '4' },
    '.ctaButton': {
      variant: 'buttons.secondary'
    },

    '.image, img': {
      maxHeight: ['50vh', '', '100vh'],
      // height: ['', '', '', '90vh'],
      borderRadius: '500px 118px 399px 256px'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.image, img': {
      maxHeight: ['50vh', '', '100vh'],
      // height: ['', '', '', '90vh'],
      borderRadius: '118px 500px  256px 399px'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: ['auto', '100vh'],
    '.slick-initialized .slick-slide > div,  .slick-slide img': {
      height: ['100vh']
    },
    '.slick-list': {
      height: ['65vh', 'unset']
    },
    '.hero_content_container ': {
      top: ['23%', '33%'],
      left: ['50%', '', '', '45%'],
      width: '90%',
      transform: 'translate(-50%, -50%)',
      '.title': {
        color: 'white',
        borderBottom: '4px dotted',
        paddingBottom: '1rem'
      },
      '.subtitle': {
        color: 'secondary',
        fontSize: ['2rem', '', '4rem'],
        position: ['', 'relative'],
        left: '4rem',
        top: '1rem'
      },
      '.ctaButton': {}
    }
  },

  homepageHeroShout: {
    '.shoutWidget': {
      backgroundColor: 'transparent',
      position: ['static', 'absolute'],
      left: '0rem',
      bottom: '3vh',
      maxWidth: 'unset',
      padding: ['1rem', '', '0rem 6rem'],
      flexDirection: ['column', 'row'],
      '.imageContainer': {
        order: '2',
        width: ['100%', '23%'],
        img: {
          border: 'none',
          borderRadius: '100px'
        }
      },
      '.content': {
        padding: '0rem 1rem',
        width: ['100%', '65%'],
        marginBottom: ['2rem', '0rem']
      },
      '.title': {
        fontSize: ['1rem', '', '1.5rem'],
        textTransform: 'uppercase',
        marginBottom: '1rem',
        fontWeight: '700',
        color: 'secondary'
      },
      '.text': {
        fontSize: ['1rem', '', '1.5rem'],
        color: '#39b1fa',
        lineHeight: '1.5',
        marginTop: '0.5rem',
        marginBottom: '1rem',
        fontFamily: 'display'
      }
    }
  },

  homepageShout: {
    padding: ['', '', '1rem'],
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '40%'],
      maxHeight: ['', '', '55vh'],
      order: ['2', '', '1']
    },
    '.image': {
      padding: ['0rem', '', '1rem', '1rem'],
      objectFit: 'cover'
    },
    '.content': {
      backgroundColor: ['#f1f1f1', '', ''],
      color: 'primary',
      margin: ['', '', '1rem'],
      padding: ['1rem 4rem 1rem 1rem', '1.5rem 4rem 1.5rem 1.5rem', '2rem 5rem 2rem 2rem'],
      width: ['100%', '100%', '60%'],
      order: ['1', '', '2']
    },
    '.text': {
      lineHeight: '1.5',
      // color: 'light',
      fontSize: ['1.5rem', '', '2rem', '2.5rem'],
      letterSpacing: '1px',
      fontFamily: 'heading'
    },
    '.title': {
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      // color: 'light',
      letterSpacing: '3px'
    },
    '.date': {
      // color: 'light',
      // display: 'none',
    },

    '.shoutCTA': {
      variant: 'buttons.secondary'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageBoxes: {
    padding: '0rem',
    'div:nth-of-type(6)': {
      backgroundColor: 'primary',
      margin: '1rem',
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(33.3% - 2rem)'],
      height: '450px',
      '.title': { color: 'secondary' },
      '.ctaLink': {
        variant: 'buttons.primary'
      }
    },
    '.box': {
      padding: '0rem',
      width: ['100%', '', '50%', '33.3%'],
      position: 'relative',
      height: '450px',
      background: 'transparent',
      color: 'white',
      margin: '0rem',
      borderRadius: '118px 399px 500px 256px'
      // ':hover img': {
      //   transform: 'skew(-10deg)',
      // },
    },
    '.image': {
      position: 'absolute',
      height: '450px',
      zIndex: '0',
      objectFit: 'cover',
      filter: 'brightness(0.9)',
      borderRadius: '118px 399px 500px 256px',
      padding: '1rem',
      transition: 'all ease-in-out 0.8s'
    },
    '.title': {
      variant: 'customVariants.title',
      padding: '0rem 2rem',
      order: '2',
      textTransform: 'capitalize',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem'],
      color: '#f5f9fa',
      zIndex: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      margin: '3rem 2rem 1rem',
      padding: '1rem 0rem',
      borderBottom: '2px dotted white',
      width: 'calc(100% - 6rem)',
      textTransform: 'uppercase',
      order: '1',
      fontWeight: '400',
      fontFamily: 'display',
      zIndex: '1'
    },
    '.text': {
      variant: 'customVariants.text',
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem',
      zIndex: '1'
    },
    '.ctaLink': {
      variant: 'buttons.secondary',
      margin: '1rem 2rem 3rem',
      order: '4',
      width: 'fit-content',
      zIndex: '1'
    }
  },

  homepageContactUs: {
    color: 'text',
    padding: '8rem 1rem',
    // backgroundColor: 'backgroundSecondary',
    textAlign: 'center',
    '.section': {},
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      padding: '1rem 2rem'
    }
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '2rem', '4rem 3rem'],
    maxWidth: 'unset',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',

    // '.innerMenuContainer': {
    //   border: 'solid 1px grey',
    // },
    '.menuSectionTitle': {
      margin: '1rem auto',
      fontSize: ['2rem', '', '', '3rem'],
      textTransform: 'capitalize',
      fontWeight: '300',
      textAlign: 'center',
      paddingBottom: '0rem',
      width: 'fit-content',
      color: 'primary'
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      fontSize: ['1.25rem', '1.5rem'],
      opacity: '0.6',
      textAlign: 'center',
      margin: ['0rem 0rem 1rem', '', ''],
      color: 'secondary',
      fontFamily: 'display'
    },
    '.menuItemContainer': {
      height: '300px',
      display: 'flex',
      alignItems: 'center',
      overflowY: 'scroll',
      MsOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    },

    '.menuItemInnerContainer, .menuItemContentContainer ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    '.menuItemNameContainer': {
      order: '4',
      marginTop: '1rem'
    },
    '.menuItemName': {
      color: 'primary',
      fontSize: ['1.1rem', '', '1.4rem'],
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      fontFamily: 'heading',
      padding: ['0rem', '', '0rem 1rem']
    },

    '.menuItemDescription': {
      maxHeight: '200px',
      overflowY: 'scroll',
      MsOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  homepageSlider: {
    padding: '0rem',
    '> div': {
      padding: '0.5rem 0px'
    },
    '.slick-slide img': {
      margin: '0rem 0.75rem'
    },
    '.slick-dots': {
      display: 'none !important'
    },
    '.mainTextContainer': {
      display: 'none'
    }
  },

  homepageInstagram: {
    flexDirection: ['column', 'column', 'column'],

    '.logoAndTextContainer': {
      display: 'none'
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%']
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  pageHeroAbout: {
    variant: 'customVariants.pageHero',
    backgroundPosition: 'bottom center'
  },

  aboutSection: {
    flexDirection: ['column', 'column', 'column', 'row'],
    padding: ['1rem', , '2rem'],
    '.lazyload-wrapper': {
      width: ['100%', '100%', '100%', '30%']
    },
    '.image': {
      order: ['', '', '2'],
      width: ['100%', '100%', '100%', '100%'],
      maxHeight: '100vh'
    },
    '.content': {
      order: ['', '', '', '1'],
      border: 'none',
      borderWidth: '5px',
      padding: ['1rem 0rem', , 8],
      width: ['100%', '100%', '100%', '68%'],
      margin: ['', '', '', 'auto 0 0']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: { order: '4' }
  },

  aboutSectionNavLinks: {
    h2: {
      backgroundColor: 'primary',
      width: '100%'
    },
    '.box': {
      height: '300px',
      // flexGrow: 'unset',
      boxShadow: '2px 2px 8px #333333'
    }
  },

  aboutTeam: {
    color: 'text',
    padding: ['8rem 1rem', '', '12rem 1rem'],
    backgroundColor: 'backgroundSecondary',
    textAlign: 'center',
    '.section': {
      padding: '2rem',
      backgroundColor: '#ffffff70'
    },
    h2: {
      color: 'black',
      textTransform: 'uppercase',
      fontSize: '2rem',
      letterSpacing: '2px',
      maxWidth: '600px',
      marginBottom: '2rem'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Services page  ====
  // ? ========================

  menu: {
    // borderLeft: ['20px solid', '25px solid', '30px solid', '50px solid'],
    padding: ['1rem', '2rem', '3rem'],
    maxWidth: 'unset',
    '.menuSectionTitle': {
      fontFamily: 'heading',
      textTransform: 'capitalize',
      color: 'primary',
      fontSize: ['1.5rem', '2rem', '2.5rem', '3rem'],
      textAlign: 'left',
      margin: '0rem 0rem 2rem',
      padding: ['0.5rem', '0.5rem 1.5rem'],
      borderTop: '3px dotted',
      borderBottom: '3px dotted',
      borderColor: 'primary',
      width: '100%',
      paddingLeft: '0.5rem'
    },
    '.menuSectionDescription': {
      whiteSpace: 'break-spaces'
    },
    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuItemName': {
      fontFamily: 'heading',
      color: '#169074',
      fontWeight: 'normal'
    },
    '.menuItemPrice': {
      color: 'secondary',
      fontFamily: 'heading',
      fontWeight: 'bold'
    },
    '.menuItemPriceVariants': {
      color: 'secondary',
      fontFamily: 'heading',
      fontWeight: 'bold'
    },
    '.variantContainer': {
      // color: '',
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.heading': {
      variant: 'customVariants.title',
      borderBottom: '2px dotted',
      width: 'fit-content',
      margin: '0 auto 2rem'
    },
    '.eventItemImage': {
      minHeight: 'unset',
      display: 'none'
    },
    '.eventItemTitle': {
      color: 'primary',
      fontSize: '1.5rem'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.modal': {
      backgroundColor: 'background'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    backgroundColor: 'primary',
    order: '4',

    '.title': {
      fontFamily: 'display',
      fontSize: '3rem',
      color: 'secondary',
      borderBottom: 'solid 1px',
      paddingBottom: '0.5rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.8'
    },
    '.text': {
      fontStyle: 'italic',
      marginBottom: '3rem',
      color: 'secondary',
      opacity: '0.7'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '2px dotted',
        borderColor: 'secondary',
        fontFamily: 'body',
        margin: '1rem 0rem',
        color: 'white',
        '::placeholder': { color: 'lightgrey' }
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '2px dotted',
        borderColor: 'secondary',
        fontFamily: 'body',
        margin: '1rem 0rem',
        color: 'white',
        '::placeholder': { color: 'lightgrey' }
      },
      '.submitBtn': {
        borderRadius: '0px',
        marginTop: '1rem',
        padding: '1rem 2rem',
        borderRadius: '10px'
      }
    }
  },

  locationMap: {
    order: '2',
    '.title': {
      fontFamily: 'display',
      fontSize: '3rem',
      color: 'primary',
      borderBottom: 'solid 1px',
      paddingBottom: '0.5rem'
    },
    '.content_container': {
      backgroundColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Color page  =====
  // ? ========================

  brunch: {
    variant: 'customVariants.menu'
  },

  // ? ========================
  // ? =====  Event page  =====
  // ? ========================

  pizza: {
    variant: 'customVariants.menu'
  },

  tentRental: {
    variant: 'customVariants.menu'
  },

  // ? ========================
  // ? ===  Haircare page  ===
  // ? ========================

  catering: {
    variant: 'customVariants.menu'
  },

  // ? ========================
  // ? =====  terms page  =====
  // ? ========================

  foodTruck: {
    variant: 'customVariants.menu'
  },

  jobIntro: {
    background: 'none',
    padding: '8vh 1rem'
  }
}
